<template>
  <div
    class="m-review-homepage"
    :class="{ [`m-review-homepage--${color}`]: color }"
    @click="$emit('click', $event)"
  >
      <div class="m-review-homepage__info">
        <div>
          <NuxtLink
            :to="getUrl(`products/${review.product?.code}.html`, false)"
            no-prefetch
          >
            <p class="m-review-homepage__title">
              {{ review.product?.name }}
            </p>
          </NuxtLink>
          <ARating
            :value="+review.rating || 0"
            size="sm"
          />
        </div>
        <a
          :href="getUrl(`products/${review.product?.code}.html`, false)"
          @click.prevent="navigatePush(`products/${review.product?.code}.html`, false)"
        >
          <AImage
            :src="image.desktop"
            :mobile-src="image.mobile"
            width="80"
            height="212"
            with-retina
            with-webp
          />
        </a>
      </div>
      <div class="m-review-homepage__review">
        <p class="i-quotes" v-html="DOMPurify.sanitize(reviewText)" />
        <p class="author">
          - {{ formattedAuthor }}
        </p>

        <div class="m-review-homepage__author">
          <AAvatar
            :name="formattedAuthor ?? ''"
            size="lg"
          />
          <div>
            <p>{{ formattedAuthor }}</p>
            <p>{{ date }}</p>
          </div>
        </div>
      </div>
  </div>
</template>

<script setup lang="ts">
import { defineComponent, computed } from 'vue'
import DOMPurify from 'isomorphic-dompurify'
import { sizes } from '@/composables/product'
import { useDays } from '@/composables/dayjs'
import { useNavigate } from '@/composables/useNavigate'

import type { PropType } from 'vue'
import type { Review } from '@/modules/nuxt-api/models/Review'

import ARating from '@/components/atoms/Rating/ARating.vue'
import AImage from '@/components/atoms/Image/AImage.vue'
import AAvatar from '@/components/atoms/Avatar/AAvatar.vue'

type ReviewHomepageColor = 'yellow' | 'blue' | 'red'

defineComponent({ name: 'MReviewHomepage' })

const props = defineProps({
  review: {
    type: Object as PropType<Review>,
    required: true
  },
  color: {
    type: String as PropType<ReviewHomepageColor>,
    default: undefined
  }
})

defineEmits(['click'])

const { dateToCalendar: toCalendar } = useDays()
const { getUrl, navigatePush } = useNavigate()

const formattedAuthor = computed((): string => {
  const [firstName, lastName] = props.review?.author?.name?.split(' ') ?? []
  if (firstName?.length && lastName?.length) {
    return `${firstName} ${lastName[0]}.`
  } else if (!firstName?.length && lastName?.length) {
    return lastName
  }
  return firstName ?? ''
})

const reviewText = computed((): string => {
  return props.review?.comment ?? ''
})

const image = computed(() => {
  const img = props.review?.product?.image?.url ?? ''
  const { mobile, desktop } = sizes.reviewHomepage

  return {
    mobile: img.replace('%w', mobile.w).replace('%h', mobile.h),
    desktop: img.replace('%w', desktop.w).replace('%h', desktop.h)
  }
})

const date = computed(() => toCalendar(props.review?.createdAtIso, false, true))
</script>

<style lang="postcss">
.m-review-homepage {
  --review-homepage-color: var(--color-yellow-50);

  position: relative;
  width: 21rem;
  height: 27.5rem;
  padding: var(--spacer-2xs) var(--spacer-2xl) 0 var(--spacer-base);

  @media (hover: hover) and (--screen-lg) {
    &:hover .m-review-homepage__title {
      color: var(--color-info-dark);
    }
  }
}

.m-review-homepage--blue {
  --review-homepage-color: var(--color-blue-50);
}

.m-review-homepage--red {
  --review-homepage-color: #fcf3ed;
}

.m-review-homepage__info {
  display: flex;
  gap: var(--spacer-2xs);
  justify-content: space-between;

  & > div {
    display: flex;
    flex-direction: column;
    gap: var(--spacer-4xs);
    align-items: flex-start;
    max-width: calc(100% - 5.75rem);
    margin-top: var(--spacer-2xl);

    & .m-review-homepage__title {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      @mixin text-lg;
    }
  }

  & .image img,
  a > .image img {
    object-fit: contain;
    width: 5rem;
    height: 13.25rem;
  }
}

.m-review-homepage__review {
  position: absolute;
  top: 12.75rem;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 1.5rem);
  height: 14.75rem;
  padding: var(--spacer-base) 1.25rem var(--spacer-base) var(--spacer-base);
  border-radius: var(--border-radius-xl);
  background-color: var(--review-homepage-color);
  user-select: none;

  & > p {
    display: -webkit-box;
    overflow: hidden;
    color: var(--color-text-dark);
    hyphens: auto;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    &::before {
      position: relative;
      top: var(--spacer-5xs);
      width: 1rem;
      height: 1rem;
      margin-right: var(--spacer-5xs);
    }

    @mixin text-base;
  }

  &::before {
    content: "";
    position: absolute;
    top: -1.125rem;
    right: 6rem;
    width: 2rem;
    height: 1.375rem;
    background-color: var(--review-homepage-color);
    mask-image: url("/assets/images/pointer.svg");
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: calc(-1 * var(--spacer-2xs));
    z-index: -1;
    display: block;
    width: 50%;
    height: 100%;
    border-radius: 0 var(--border-radius-xl) var(--border-radius-xl) 0;
    background-color: var(--review-homepage-color);
    transform: skew(7deg);
  }

  @media (--screen-lg) {
    user-select: none;
  }
}

.m-review-homepage__author {
  display: flex;
  gap: var(--spacer-3xs);
  margin-top: var(--spacer-xs);

  & > div {
    padding: var(--spacer-5xs) 0;

    & > p:first-child {
      color: var(--color-text-dark);

      & + p {
        color: var(--color-text-light);

        @mixin text-sm;
      }

      @mixin text-base-semibold;
    }
  }
}

@media (--screen-xs) {
  .m-review-homepage {
    width: 11.75rem;
    height: 16.25rem;
    padding: var(--spacer-2xs) var(--spacer-xs) 0 var(--spacer-2xs);
  }

  .m-review-homepage__info {
    & > div {
      max-width: calc(100% - 4.25rem);
      margin-top: var(--spacer-2xs);

      & .m-review-homepage__title {
        hyphens: auto;

        @mixin text-sm-bold;
      }
    }

    & .rating {
      --rating-gap: var(--spacer-5xs);
      --rating-size: var(--spacer-xs);
    }

    & > .image img {
      width: 3.5rem;
      height: 11.25rem;
    }
  }

  .m-review-homepage__review {
    top: 8rem;
    width: calc(100% - 1rem);
    height: 8.23rem;
    padding: var(--spacer-xs) 0 var(--spacer-xs) var(--spacer-2xs);
    border-radius: var(--border-radius-lg);

    & > p {
      width: 10rem;

      @mixin text-sm;
    }

    &::before {
      top: -1rem;
      right: 3.75rem;
    }

    &::after {
      border-radius: 0 var(--border-radius-lg) var(--border-radius-lg) 0;
      transform: skew(5deg);
    }
  }

  .m-review-homepage__author {
    display: none;
  }
}
</style>
